import { For, Suspense } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import { Link, Container, Page, Heading, Button, HorizontalRule, Section, Picture, LinkButton } from '@troon/ui';
import { Meta, Title } from '@solidjs/meta';
import { Icon } from '@troon/icons';
import { createAsync } from '@solidjs/router';
import { Hero } from '../../components/hero/photo';
import { getConfigValue } from '../../modules/config';
import { VideoSection } from '../../components/layouts/video-section';
import { createContentfulRequest } from '../api/content/[model]/_client';
import { ContentCard } from '../../components/card';
import type { RouteDefinition } from '@solidjs/router';
import type { JSX } from 'solid-js';

export default function PrivePage() {
	const benefits = createAsync(async () => getBenefits('prive-privileges'), { deferStream: true });
	return (
		<>
			<Title>Troon Privé/Privileges</Title>
			<Meta
				name="description"
				content="Members of Troon Privé-managed clubs have exclusive access to extraordinary golf and lifestyle benefits around the world."
			/>
			<NoHydration>
				<Hero fullWidth src={`${getConfigValue('IMAGE_HOST')}/digital/hero/member-programs.jpg`}>
					<img src="/assets/logos/prive.svg" alt="Troon Privé/Privileges" class="mx-8 w-full max-w-3xl" />
				</Hero>
			</NoHydration>

			<Container>
				<Page>
					<VideoSection
						poster={`${getConfigValue('IMAGE_HOST')}/digital/video/prive.png`}
						type="youtube"
						code="xNn-JC9_dI4"
					>
						<Heading as="h2">
							Qualified members of Troon Privé managed clubs receive exclusive access to extraordinary golf and
							lifestyle benefits around the world.
						</Heading>
						<p>
							Full Category members of participating Troon Privé managed clubs may request tee times and amenities use
							at other private clubs, receive special rates with Cliff Drysdale Tennis, and access a live booking link
							for preferred Troon Advantage rates on rounds at spectacular resort and daily fee clubs around the world.
							All members enjoy discounts with Avis, Ship Sticks, Golfzon Social, Suntex Marinas, and more.
						</p>
						<p>
							<Button as={Link} href="#benefits" class="size-fit">
								Explore Benefits
							</Button>
						</p>
					</VideoSection>

					<NoHydration>
						<HorizontalRule />

						<Section id="benefits">
							<Heading as="h2" size="h4">
								Private Club Full Members receive access to
							</Heading>
							<div class="mb-12 grid grid-cols-2 gap-4 md:gap-8 lg:grid-cols-4">
								<For each={subpages}>
									{(data) => (
										<article class="group relative flex shrink grow basis-full flex-col gap-4">
											<div class="overflow-hidden rounded">
												<Picture
													src={data.image}
													sizes={[[300, 300]]}
													alt=""
													class="pointer-events-none aspect-square w-full object-cover group-hover:scale-105 motion-safe:transition-transform motion-safe:duration-200"
												/>
											</div>
											<div class="flex flex-col gap-1">
												<Heading as="h3" size="h5">
													{data.title}
												</Heading>
												<p class="text-sm">{data.children}</p>
											</div>
											<LinkButton
												href={data.url}
												class="-ms-2 flex w-fit flex-row gap-2 after:absolute after:inset-0 after:z-0"
											>
												{data.linkText}
												<Icon name="chevron-right" />
											</LinkButton>
										</article>
									)}
								</For>
							</div>

							<Heading as="h2" size="h4">
								All Private Club Members (Including Full Members) receive access to
							</Heading>
							<div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:gap-6 lg:grid-cols-3">
								<Suspense>
									<For each={benefits()?.fields.cards}>
										{(benefit) => <ContentCard {...benefit!.fields} level="h4" />}
									</For>
								</Suspense>
							</div>
						</Section>
					</NoHydration>
				</Page>
			</Container>
		</>
	);
}

export const route = { info: { hasHero: true } } satisfies RouteDefinition;

const subpages: Array<{ image: string; title: string; children: string; url: string; linkText: JSX.Element }> = [
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/hero/private.jpg`,
		title: 'Private Club Golf',
		children: 'Access and play 145+ private courses at the Troon Privé Member Rate.',
		url: '/member-programs/private-club-golf',
		linkText: (
			<>
				Learn more<span class="sr-only"> about Troon Privé Private Club Golf</span>
			</>
		),
	},
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/hero/prive-amenities.jpg`,
		title: 'Private Club Amenities',
		children: 'Enjoy amenities at participating private clubs, with access to fitness, tennis, aquatics and/or dining.',
		url: '/member-programs/private-club-amenities',
		linkText: (
			<>
				Learn more<span class="sr-only"> about Troon Privé Private Club Amenities</span>
			</>
		),
	},
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/hero/advantage.jpeg`,
		title: 'Resort & Daily Fee Golf',
		children: 'Preferred golf rates at 200+ resort and daily fee courses worldwide.',
		url: '/member-programs/advantage-resort-daily-fee-golf',
		linkText: (
			<>
				Learn more<span class="sr-only"> about Troon Advantage</span>
			</>
		),
	},
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/hero/tennis-experiences.jpg`,
		title: 'Cliff Drysdale Tennis',
		children: 'Preferred access, rates and benefits at clubs and resorts throughout the US and Bermuda.',
		url: '/member-programs/tennis-experiences',
		linkText: (
			<>
				Learn more<span class="sr-only"> about Cliff Drysdale Tennis</span>
			</>
		),
	},
];

const getBenefits = createContentfulRequest('cards');
